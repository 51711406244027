.container {
  margin: 0 auto;
  max-width: 120rem;
  width: 100%;
  padding-top: 0.625rem;
}

.title {
  font-size: 1.44rem;
  font-weight: 900;
  line-height: 1.1;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 1rem;
  margin-right: auto;
  text-rendering: optimizeLegibility;
}
.title > a {
  color: var(--primary-color-light);
  text-decoration: none;
}

.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 0.625rem;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}


.rightSideLink {
  text-decoration: none;
  padding: 0.2rem 0.5rem 0.2rem 0.5rem;
  margin: 0.2rem;
}

.rightSideLink:hover {
  color: white;
  background-color: lightgray;
  border-radius: 5%;
}

.rightSideLink:active {
  color: white;
  background-color: gray;
}
