.footer {
  background-color: #ebf3f5;
  padding: 10px;
}
.footer .title {
  font-size: 1.0rem;
  margin-top: 0;
  margin-bottom: 2.4rem;
}
.footer .title > a {
  color: var(--primary-color-light);
  text-decoration: none;
}

.bio {
  margin-bottom: 7rem;
}

.linkContainer {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
}

.linkContainer a {
  text-decoration: none;
  padding: 3px;
}

.nextPost {
  display: flex;
  justify-content: space-between;
}
.nextPost .next {
  flex-grow: 1;
  flex-shrink: 1;
  text-align: right;
  text-decoration: none;
}

.nextPost .previous {
  text-decoration: none;
}

.footerSubscribe {
  display:flex;
  align-items: center;
  flex-direction: column;
}