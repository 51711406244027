.Pagination {
  display: block;
  height: 2rem;
}
  
.Pagination .previous {
  margin-right: 0.5rem;
  text-decoration: none;
  border: 3px solid lightgray;
  padding: 0.3rem 0.6rem;
}

.Pagination .next {
  margin-left: 0.5rem;
  text-decoration: none;
  border: 3px solid lightgray;
  padding: 0.3rem 0.6rem;
}

.Pagination .next:hover {
  background-color: lightgray;
  color: white;
}

.Pagination .previous:hover {
  background-color: lightgray;
  color: white;
}