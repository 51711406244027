@import url(https://fonts.googleapis.com/css?family=Heebo:300|Source+Sans+Pro:300&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato|Raleway|Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css?family=Courier+Prime|Pangolin|Patrick+Hand&display=swap);
:root {
  --primary-color: #435051;
  --primary-color-light: #799496;
  --primary-color-opaque: rgba(121, 148, 150, .4);
  --primary-color-medium: #5f6161;
}

* {
  box-sizing: border-box;
}

html {
  color: rgba(0, 0, 0, 0.9);
  /*font: 100%/1.75 'europa', sans-serif;*/
  font: 100%/1.75 'Lato', sans-serif;
  font-weight: 300;
  font-size: 18px;
  letter-spacing: 0.2em;
  line-height: 1.6em;
  overflow-anchor: auto;
}

body {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  color: #5f6161;
  color: var(--primary-color-medium);
  font-size: 19px;
  letter-spacing: 0.01em;
  line-height: 1.6em;
  margin: 0;
  padding: 0;
  word-wrap: break-word;
}

a {
  color: #435051;
  color: var(--primary-color);
}
a:hover {
  text-decoration: none;
}

p, pre, ul, ol {
  margin-top: 0;
  margin-bottom: 1.75rem;
}
h2 {
  color: #799496;
  color: var(--primary-color-light);
}

.spoiler {
  color: transparent;
  text-shadow: 0 0 8px rgba(0,0,0,0.5);
}

.spoiler:hover {
  color: black;
  text-shadow: none;
}

.spoiler:active {
  color: black;
  text-shadow: none;
}

.quizBox {
  border: 3px solid lightgray;
  margin: 1rem 0 1rem 0;
  width: 100%;
}

.quizBox h2 {
  margin-top: 1rem !important;
  width: 90%;
  word-wrap: break-word;
}

.quizBox div {
  width: 95%;
  word-wrap: break-word;
}

.quizBox h3 {
  word-wrap: break-word;
  width: 100%;
  margin-top: 1rem !important;
}

.imageWithBorder {
  border: 5px solid black;
  max-width: 30rem !important;
  width: 95%;
  margin-bottom: 2rem;
}

.imageWithBorderHorizontal {
  border: 5px solid black;
  width: 95%;
  margin-bottom: 2rem;
}

.youtubeVideo {
  max-width: 560px;
  height: 315px;
  width: 95%;
}

.quoteBox {
  border-left: 3px solid lightgray;
  margin-left: 2rem;
  padding-left: 1rem;
}

.subscribeLink {
  text-decoration: none;
  border: 4px solid lightblue;
  padding: 5px;
  font-weight: 900;
  width: 6rem;
  text-align: center;
}

.subscribeLink:hover {
  color: white;
  background-color: gray;
}


.BmcButton img {
  width: 35px !important;
  margin-bottom: 1px !important;
  box-shadow: none !important;
  border: none !important;
  vertical-align: middle !important;
}

.BmcButton {
  padding: 7px 5px 7px 10px !important;
  line-height: 35px !important;
  height:51px !important;
  min-width:217px !important;
  text-decoration: none !important;
  display:inline-flex !important;
  color:#FFFFFF !important;
  background-color:#FF813F !important;
  border-radius: 5px !important;
  border: 1px solid transparent !important;
  padding: 7px 5px 7px 10px !important;
  font-size: 22px !important;
  letter-spacing: 0.6px !important;
  box-shadow: 0px 1px 2px rgba(190, 190, 190, 0.5) !important;
  -webkit-box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;
  margin: 0 auto !important;
  font-family:'Cookie', cursive !important;
  box-sizing: border-box !important;
  -webkit-transition: 0.3s all linear !important;
  transition: 0.3s all linear !important;
}

.BmcButton:hover {
  -webkit-box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;
  text-decoration: none !important;
  box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;
  opacity: 0.85 !important;
  color:#FFFFFF !important;
}

.BmcButton:active {
  -webkit-box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;
  text-decoration: none !important;
  box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;
  opacity: 0.85 !important;
  color:#FFFFFF !important;
} 

.BmcButton:focus {
  -webkit-box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;
  text-decoration: none !important;
  box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;
  opacity: 0.85 !important;
  color:#FFFFFF !important;
} 

.BmcSpan {
  margin-left:15px;font-size:28px !important;
}

time {
  font-weight: lighter;
}

.ArticleMeta_tags__1IJ9w,
.ArticleMeta_tags__1IJ9w li {
  display: inline;
  list-style: none;
  padding: 0;
  margin: 0;
  text-decoration: none;
}

.ArticleMeta_individualTag__1Dsa- {
	background-color: #7fc4d4;
	background-color: #f4f5f6;
	color: #44576b;
	margin-right: 6px;
	border-radius: 20px;
	font-size: 13px;
	font-weight: 900;
	padding: 7px 15px;
	text-decoration: none;
}

.ArticleMeta_individualTag__1Dsa-:hover {
	background-color: #00B4B4;
	color: white;
}

.ArticleMeta_container__26Al8 {
  display: flex;
}

.ArticleMeta_margin__29AFQ {
  margin: 4px;
}

.ArticleMeta_link__2MfOi {
  text-decoration: none;
}

@media (max-width: 1000px) {
  .ArticleMeta_hideMobile__3rfGS {
    display: none;
  }
  small {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .ArticleMeta_tags__1IJ9w {
    display: flex;
    justify-content: center;
  }
}

/*.tags li:not(:first-child)::before {
  content: ', ';
}*/
.ArticleSummary_ArticleSummary__3I5Qk h2 {
  font-weight: 600;
  margin-bottom: 8px;
  margin-top: 0;
}

.ArticleSummary_ArticleSummary__3I5Qk a {
  text-decoration: none;
}

.ArticleSummary_ArticleSummary__3I5Qk p {
  color: var(--primary-color-medium);
}

@media (max-width: 1000px) {
  .ArticleSummary_articleTitle__1NHcO {
    text-align: center;
  }
}
.ResAppBar_appBarRoot__2gFjf {
  background-color: white !important;
  color: var(--primary-color-light);
}

.ResAppBar_toolbarRoot__3M8oz {
  color: black;
}

.ResAppBar_sideBarIcon__3Qblr {
  color: var(--primary-color-light);
}

.ResAppBar_centerMenuTitle__3jMyd {
  padding-left: 0.5rem;
  color: var(--primary-color-light);
}

.ResAppBar_list__16-1s {
  width: 200px; 
}

.ResAppBar_menuTitle__2Y0BT {
  color: var(--primary-color-light);
  text-decoration: none;
  margin-left: 0.5rem;
}

.ResAppBar_menuTitle__2Y0BT a {
  text-decoration: none;
}

.ResAppBar_smallMenuTitle__1qH19 {
  color: var(--primary-color-light);
  text-decoration: none;
}

.ResAppBar_menuIcon__1xsZ6 {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  cursor: pointer;
}

.ResAppBar_menuIcon__1xsZ6 a {
  text-decoration: none;
  border-radius: 10%;
  padding: 0.2rem 0.5rem 0.2rem 0.5rem;
  margin: 0.2rem;
  color: var(--primary-color-light);
}

.ResAppBar_menuIcon__1xsZ6 a:hover {
  color: white;
  background-color: lightgray;
}


.ResAppBar_centerMobile__3Jqc4 {
  display: flex;
  align-items: center;
}

.ResAppBar_show__77zkE {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition-delay: 1s;
          transition-delay: 1s;
  -webkit-transition-duration: 2s;
          transition-duration: 2s;
}

.ResAppBar_hide__3T7iZ {
  -webkit-transition-duration: 2s;
          transition-duration: 2s;
  -webkit-transition-delay: 1s;
          transition-delay: 1s;
  -webkit-transform: translateY(-110%);
          transform: translateY(-110%);
}



.Header_allSiteHeader__2FkC7 {
	padding-bottom: 3rem;
}
.Footer_footer__2NNgL {
  background-color: #ebf3f5;
  padding: 10px;
}
.Footer_footer__2NNgL .Footer_title__1NScE {
  font-size: 1.0rem;
  margin-top: 0;
  margin-bottom: 2.4rem;
}
.Footer_footer__2NNgL .Footer_title__1NScE > a {
  color: var(--primary-color-light);
  text-decoration: none;
}

.Footer_bio__NXch2 {
  margin-bottom: 7rem;
}

.Footer_linkContainer__15GqB {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
}

.Footer_linkContainer__15GqB a {
  text-decoration: none;
  padding: 3px;
}

.Footer_nextPost__3eUdk {
  display: flex;
  justify-content: space-between;
}
.Footer_nextPost__3eUdk .Footer_next__2nZ0x {
  flex-grow: 1;
  flex-shrink: 1;
  text-align: right;
  text-decoration: none;
}

.Footer_nextPost__3eUdk .Footer_previous__1XhCB {
  text-decoration: none;
}

.Footer_footerSubscribe__1Hv2w {
  display:flex;
  align-items: center;
  flex-direction: column;
}
.SubscribeForm_subscribeForm__3Tbw9 {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 1rem;
	margin-top: 1rem;
}

.SubscribeForm_hideForm__3zfmx {
	display: none;
}

.SubscribeForm_showForm__2VuRk {
	display: block;
}

.SubscribeForm_subscribeForm__3Tbw9 button {
	width: 12rem;
	height: 2rem;
	border: 2px solid black;
	background-color: white;
}

.SubscribeForm_subscribeForm__3Tbw9 button:hover {
	color: white;
	background-color: black;
}

.SubscribeForm_subscribeForm__3Tbw9 input {
	width: 12rem;
	height: 2rem;
	margin-bottom: 0.3rem;
	display: block;
	border: 2px solid lightgray;
}
.Pagination_Pagination__2Xt2h {
  display: block;
  height: 2rem;
}
  
.Pagination_Pagination__2Xt2h .Pagination_previous__1akUm {
  margin-right: 0.5rem;
  text-decoration: none;
  border: 3px solid lightgray;
  padding: 0.3rem 0.6rem;
}

.Pagination_Pagination__2Xt2h .Pagination_next__7Ba4Y {
  margin-left: 0.5rem;
  text-decoration: none;
  border: 3px solid lightgray;
  padding: 0.3rem 0.6rem;
}

.Pagination_Pagination__2Xt2h .Pagination_next__7Ba4Y:hover {
  background-color: lightgray;
  color: white;
}

.Pagination_Pagination__2Xt2h .Pagination_previous__1akUm:hover {
  background-color: lightgray;
  color: white;
}
.BlogIndexPage_title__341hM {
  font-size: 1.7rem;
  font-weight: 900;
  line-height: 3.5rem;
  margin-top: 0;
  margin-bottom: 0.225rem;
  text-rendering: optimizeLegibility;
}
.BlogIndexPage_title__341hM a {
  color: black;
  text-decoration: none;
}

.BlogIndexPage_blogIndexContent__1qp2G {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 80vh;
}

.BlogIndexPage_articlesList__1V39R {
  padding-top: 2.225rem;
}

.BlogIndexPage_articlesList__1V39R li {
  list-style: none;
  padding: 0;
  margin: 0 0 1.225rem;
  width: 90%;
  max-width: 42rem;
}

.BlogIndexPage_articlesList__1V39R article {
    border-bottom: 1px solid lightgray;
}


@media (max-width: 1000px) {
  .BlogIndexPage_articlesList__1V39R li {
    width: 90%;
  }
}

.LoadingIndicator_LoadingIndicator__3KSl0 {
  position: absolute;
  left: 0;
  top: 0;
  height: 3px;
  width: 100%;
  background-color: var(--primary-color-light);
  background-size: 35px 35px;
  z-index: 1000;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2) inset;
  -webkit-transition: opacity ease-in 300ms, -webkit-transform ease-in 300ms;
  transition: opacity ease-in 300ms, -webkit-transform ease-in 300ms;
  transition: transform ease-in 300ms, opacity ease-in 300ms;
  transition: transform ease-in 300ms, opacity ease-in 300ms, -webkit-transform ease-in 300ms;
  -webkit-transition-delay: 0;
          transition-delay: 0;
  -webkit-transform-origin: left center;
          transform-origin: left center;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  opacity: 0;
}

.LoadingIndicator_LoadingIndicator__3KSl0.LoadingIndicator_active__3EncM {
  opacity: 1;
  -webkit-transition-delay: 333ms;
          transition-delay: 333ms;
  -webkit-animation: LoadingIndicator_LoadingIndicator-animation__2icwd 10s ease-out;
          animation: LoadingIndicator_LoadingIndicator-animation__2icwd 10s ease-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@-webkit-keyframes LoadingIndicator_LoadingIndicator-animation__2icwd {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
  }
  10% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3);
  }
  50% {
    -webkit-transform: scaleX(0.7);
            transform: scaleX(0.7);
  }
  90% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}

@keyframes LoadingIndicator_LoadingIndicator-animation__2icwd {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
  }
  10% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3);
  }
  50% {
    -webkit-transform: scaleX(0.7);
            transform: scaleX(0.7);
  }
  90% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}
.BlogLayout_container__1fiRb {
  margin: 0 auto;
  max-width: 120rem;
  width: 100%;
  padding-top: 0.625rem;
}

.BlogLayout_title__3HyDU {
  font-size: 1.44rem;
  font-weight: 900;
  line-height: 1.1;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 1rem;
  margin-right: auto;
  text-rendering: optimizeLegibility;
}
.BlogLayout_title__3HyDU > a {
  color: var(--primary-color-light);
  text-decoration: none;
}

.BlogLayout_header__2Aht3 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 0.625rem;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}


.BlogLayout_rightSideLink__1uv97 {
  text-decoration: none;
  padding: 0.2rem 0.5rem 0.2rem 0.5rem;
  margin: 0.2rem;
}

.BlogLayout_rightSideLink__1uv97:hover {
  color: white;
  background-color: lightgray;
  border-radius: 5%;
}

.BlogLayout_rightSideLink__1uv97:active {
  color: white;
  background-color: gray;
}

.BlogPostLayout_title__2L_2_ {
  font-weight: 900;
  line-height: 1.1;
  text-rendering: optimizeLegibility;
  text-align: center;
  width: 100%;
}
.BlogPostLayout_title__2L_2_ > a {
  text-decoration: none;
}

.BlogPostLayout_articlePage__1bz4r {
  max-width: 200rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.BlogPostLayout_articleContainer__3Gkuy {
  max-width: 42rem;
  width: 96%;
  margin-left: 2%;
}

.BlogPostLayout_header__1Ny4T {
  margin-bottom: 2.4rem;  
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 42rem;
}
.BlogPostLayout_header__1Ny4T .BlogPostLayout_title__2L_2_ {
  font-size: 2.5rem;
  margin-top: 1.7rem;
  margin-bottom: 5px;
}

.BlogPostLayout_header__1Ny4T small {
  border-bottom: 1px solid lightgray;
  padding-bottom: 5px;
}

.BlogPostLayout_content__1mzTO hr {
  background: hsla(0,0%,0%,0.2);
  border: none;
  height: 1px;
}

.BlogPostLayout_content__1mzTO h2,
.BlogPostLayout_content__1mzTO h3,
.BlogPostLayout_content__1mzTO h4,
.BlogPostLayout_content__1mzTO h5,
.BlogPostLayout_content__1mzTO h6 {
  font-weight: 900;
  line-height: 1.1;
  margin-top: 3.5rem;
  text-rendering: optimizeLegibility;
}
  
/**
* Based on copypasta from Remy Bach and Sarah Drasner
*/
.BlogPostLayout_content__1mzTO code,
.BlogPostLayout_content__1mzTO pre {
  color: white;
  font-family: Consolas,Menlo,Monaco,source-code-pro,Courier New,monospace;
  font-feature-settings: normal;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
       tab-size: 4;
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
}
  
/* Code blocks */
.BlogPostLayout_content__1mzTO pre {
  overflow: auto;
  padding: 1em;
}

.BlogPostLayout_content__1mzTO :not(pre) > code,
.BlogPostLayout_content__1mzTO pre {
  background: hsla(0,0%,0%,0.9);
}
  
.BlogPostLayout_content__1mzTO pre::-moz-selection,
.BlogPostLayout_content__1mzTO pre::-moz-selection {
  background: hsl(207, 4%, 16%);
}
  
.BlogPostLayout_content__1mzTO pre::-moz-selection,
.BlogPostLayout_content__1mzTO pre::selection {
  background: hsl(207, 4%, 16%);
}
  
/* Text Selection colour */
.BlogPostLayout_content__1mzTO pre::-moz-selection,
.BlogPostLayout_content__1mzTO pre ::-moz-selection {
  text-shadow: none;
  background: hsla(0, 0%, 100%, 0.15);
}

.BlogPostLayout_content__1mzTO pre::selection,
.BlogPostLayout_content__1mzTO pre ::selection {
  text-shadow: none;
  background: hsla(0, 0%, 100%, 0.15);
}

/* Inline code */
.BlogPostLayout_content__1mzTO :not(pre) > code {
  border-radius: .3em;
  background: rgba(255,229,100,0.2);
  color: #1a1a1a;
  padding: .15em .2em .05em;
  white-space: normal;
}
.BlogPostLayout_content__1mzTO a > code {
  color: var(--primary-color);
}

.BlogPostLayout_content__1mzTO .highlighted-line {
  background-color: hsla(207, 95%, 15%, 1);
  display: block;
  margin-right: -1em;
  margin-left: -1em;
  padding-right: 1em;
  padding-left: 0.75em;
  border-left: 0.25em solid #ffa7c4;
}

.BlogPostLayout_content__1mzTO .token.attr-name {
  color: rgb(173, 219, 103);
  font-style: italic;
}

.BlogPostLayout_content__1mzTO .token.comment {
  color: rgb(99, 119, 119);
}

.BlogPostLayout_content__1mzTO .token.string,
.BlogPostLayout_content__1mzTO .token.url {
  color: rgb(173, 219, 103);
}

.BlogPostLayout_content__1mzTO .token.variable {
  color: rgb(214, 222, 235);
}

.BlogPostLayout_content__1mzTO .token.number {
  color: rgb(247, 140, 108);
}

.BlogPostLayout_content__1mzTO .token.builtin,
.BlogPostLayout_content__1mzTO .token.char,
.BlogPostLayout_content__1mzTO .token.constant,
.BlogPostLayout_content__1mzTO .token.function {
  color: rgb(130, 170, 255);
}

.BlogPostLayout_content__1mzTO .token.punctuation {
  color: rgb(199, 146, 234);
}

.BlogPostLayout_content__1mzTO .token.selector,
.BlogPostLayout_content__1mzTO .token.doctype {
  color: rgb(199, 146, 234);
  font-style: 'italic';
}

.BlogPostLayout_content__1mzTO .token.class-name {
  color: rgb(255, 203, 139);
}

.BlogPostLayout_content__1mzTO .token.tag,
.BlogPostLayout_content__1mzTO .token.operator,
.BlogPostLayout_content__1mzTO .token.keyword {
  color: #ffa7c4;
}

.BlogPostLayout_content__1mzTO .token.boolean {
  color: rgb(255, 88, 116);
}

.BlogPostLayout_content__1mzTO .token.property {
  color: rgb(128, 203, 196);
}

.BlogPostLayout_content__1mzTO .token.namespace {
  color: rgb(178, 204, 214);
}
