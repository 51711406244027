.title {
  font-size: 1.7rem;
  font-weight: 900;
  line-height: 3.5rem;
  margin-top: 0;
  margin-bottom: 0.225rem;
  text-rendering: optimizeLegibility;
}
.title a {
  color: black;
  text-decoration: none;
}

.blogIndexContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 80vh;
}

.articlesList {
  padding-top: 2.225rem;
}

.articlesList li {
  list-style: none;
  padding: 0;
  margin: 0 0 1.225rem;
  width: 90%;
  max-width: 42rem;
}

.articlesList article {
    border-bottom: 1px solid lightgray;
}


@media (max-width: 1000px) {
  .articlesList li {
    width: 90%;
  }
}