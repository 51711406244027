.ArticleSummary h2 {
  font-weight: 600;
  margin-bottom: 8px;
  margin-top: 0;
}

.ArticleSummary a {
  text-decoration: none;
}

.ArticleSummary p {
  color: var(--primary-color-medium);
}

@media (max-width: 1000px) {
  .articleTitle {
    text-align: center;
  }
}