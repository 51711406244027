@import url('https://fonts.googleapis.com/css?family=Heebo:300|Source+Sans+Pro:300&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato|Raleway|Source+Sans+Pro&display=swap');
@import url("https://fonts.googleapis.com/css?family=Courier+Prime|Pangolin|Patrick+Hand&display=swap");


:root {
  --primary-color: #435051;
  --primary-color-light: #799496;
  --primary-color-opaque: rgba(121, 148, 150, .4);
  --primary-color-medium: #5f6161;
}

:global * {
  box-sizing: border-box;
}

:global html {
  color: rgba(0, 0, 0, 0.9);
  /*font: 100%/1.75 'europa', sans-serif;*/
  font: 100%/1.75 'Lato', sans-serif;
  font-weight: 300;
  font-size: 18px;
  letter-spacing: 0.2em;
  line-height: 1.6em;
  overflow-anchor: auto;
}

:global body {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  color: var(--primary-color-medium);
  font-size: 19px;
  letter-spacing: 0.01em;
  line-height: 1.6em;
  margin: 0;
  padding: 0;
  word-wrap: break-word;
}

:global a {
  color: var(--primary-color);
}
:global a:hover {
  text-decoration: none;
}

:global p,
:global pre,
:global ul,
:global ol {
  margin-top: 0;
  margin-bottom: 1.75rem;
}
:global h2 {
  color: var(--primary-color-light);
}

:global(.spoiler) {
  color: transparent;
  text-shadow: 0 0 8px rgba(0,0,0,0.5);
}

:global(.spoiler:hover) {
  color: black;
  text-shadow: none;
}

:global(.spoiler:active) {
  color: black;
  text-shadow: none;
}

:global(.quizBox) {
  border: 3px solid lightgray;
  margin: 1rem 0 1rem 0;
  width: 100%;
}

:global(.quizBox) :global h2 {
  margin-top: 1rem !important;
  width: 90%;
  word-wrap: break-word;
}

:global(.quizBox) :global div {
  width: 95%;
  word-wrap: break-word;
}

:global(.quizBox) :global h3 {
  word-wrap: break-word;
  width: 100%;
  margin-top: 1rem !important;
}

:global(.imageWithBorder) {
  border: 5px solid black;
  max-width: 30rem !important;
  width: 95%;
  margin-bottom: 2rem;
}

:global(.imageWithBorderHorizontal) {
  border: 5px solid black;
  width: 95%;
  margin-bottom: 2rem;
}

:global(.youtubeVideo) {
  max-width: 560px;
  height: 315px;
  width: 95%;
}

:global(.quoteBox) {
  border-left: 3px solid lightgray;
  margin-left: 2rem;
  padding-left: 1rem;
}

:global(.subscribeLink) {
  text-decoration: none;
  border: 4px solid lightblue;
  padding: 5px;
  font-weight: 900;
  width: 6rem;
  text-align: center;
}

:global(.subscribeLink:hover) {
  color: white;
  background-color: gray;
}


:global(.BmcButton) :global img {
  width: 35px !important;
  margin-bottom: 1px !important;
  box-shadow: none !important;
  border: none !important;
  vertical-align: middle !important;
}

:global(.BmcButton) {
  padding: 7px 5px 7px 10px !important;
  line-height: 35px !important;
  height:51px !important;
  min-width:217px !important;
  text-decoration: none !important;
  display:inline-flex !important;
  color:#FFFFFF !important;
  background-color:#FF813F !important;
  border-radius: 5px !important;
  border: 1px solid transparent !important;
  padding: 7px 5px 7px 10px !important;
  font-size: 22px !important;
  letter-spacing: 0.6px !important;
  box-shadow: 0px 1px 2px rgba(190, 190, 190, 0.5) !important;
  -webkit-box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;
  margin: 0 auto !important;
  font-family:'Cookie', cursive !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  -o-transition: 0.3s all linear !important;
  -webkit-transition: 0.3s all linear !important;
  -moz-transition: 0.3s all linear !important;
  -ms-transition: 0.3s all linear !important;
  transition: 0.3s all linear !important;
}

:global(.BmcButton:hover) {
  -webkit-box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;
  text-decoration: none !important;
  box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;
  opacity: 0.85 !important;
  color:#FFFFFF !important;
}

:global(.BmcButton:active) {
  -webkit-box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;
  text-decoration: none !important;
  box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;
  opacity: 0.85 !important;
  color:#FFFFFF !important;
} 

:global(.BmcButton:focus) {
  -webkit-box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;
  text-decoration: none !important;
  box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;
  opacity: 0.85 !important;
  color:#FFFFFF !important;
} 

:global(.BmcSpan) {
  margin-left:15px;font-size:28px !important;
}
