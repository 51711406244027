time {
  font-weight: lighter;
}

.tags,
.tags li {
  display: inline;
  list-style: none;
  padding: 0;
  margin: 0;
  text-decoration: none;
}

.individualTag {
	background-color: #7fc4d4;
	background-color: #f4f5f6;
	color: #44576b;
	margin-right: 6px;
	border-radius: 20px;
	font-size: 13px;
	font-weight: 900;
	padding: 7px 15px;
	text-decoration: none;
}

.individualTag:hover {
	background-color: #00B4B4;
	color: white;
}

.container {
  display: flex;
}

.margin {
  margin: 4px;
}

.link {
  text-decoration: none;
}

@media (max-width: 1000px) {
  .hideMobile {
    display: none;
  }
  small {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .tags {
    display: flex;
    justify-content: center;
  }
}

/*.tags li:not(:first-child)::before {
  content: ', ';
}*/