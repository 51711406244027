.appBarRoot {
  background-color: white !important;
  color: var(--primary-color-light);
}

.toolbarRoot {
  color: black;
}

.sideBarIcon {
  color: var(--primary-color-light);
}

.centerMenuTitle {
  padding-left: 0.5rem;
  color: var(--primary-color-light);
}

.list {
  width: 200px; 
}

.menuTitle {
  color: var(--primary-color-light);
  text-decoration: none;
  margin-left: 0.5rem;
}

.menuTitle a {
  text-decoration: none;
}

.smallMenuTitle {
  color: var(--primary-color-light);
  text-decoration: none;
}

.menuIcon {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  cursor: pointer;
}

.menuIcon a {
  text-decoration: none;
  border-radius: 10%;
  padding: 0.2rem 0.5rem 0.2rem 0.5rem;
  margin: 0.2rem;
  color: var(--primary-color-light);
}

.menuIcon a:hover {
  color: white;
  background-color: lightgray;
}


.centerMobile {
  display: flex;
  align-items: center;
}

.show {
  transform: translateY(0);
  transition-delay: 1s;
  transition-duration: 2s;
}

.hide {
  transition-duration: 2s;
  transition-delay: 1s;
  transform: translateY(-110%);
}


