.subscribeForm {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 1rem;
	margin-top: 1rem;
}

.hideForm {
	display: none;
}

.showForm {
	display: block;
}

.subscribeForm button {
	width: 12rem;
	height: 2rem;
	border: 2px solid black;
	background-color: white;
}

.subscribeForm button:hover {
	color: white;
	background-color: black;
}

.subscribeForm input {
	width: 12rem;
	height: 2rem;
	margin-bottom: 0.3rem;
	display: block;
	border: 2px solid lightgray;
}